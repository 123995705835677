import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { Email, WhatsApp } from '@material-ui/icons';

import { Button, FormTextField, OffenderTypeTextField } from '../../atoms';
import {
  editAccountValidation,
  editFleetOnlyAccountValidation,
  signUpValidation,
} from '../../../validators';
import { ErrorObject, BaseFormProps, EditAccountProps } from '../../../types';
import { useOnboardStatus } from '../../../react-queries';
import { OffenderIdentifierType, OffenderIdentifierTypeDescription } from '../../../enums';
import userService from '../../../services/api/user/user.service';
import { useUserId } from '../../../hooks/global';
import { logout } from '../../../services';

export const EditAccountForm: React.FC<BaseFormProps<EditAccountProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
  editAccount = false,
  isFleetOnly = false,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const onboardStatus = useOnboardStatus();
  const individualProfileTypes = [OffenderIdentifierType.SaIdNumber, OffenderIdentifierType.Trn];
  const { userId } = useUserId();

  const { mutate: mutateDeleteuser, isLoading: isLoadingDeleteUser } = useMutation(
    () => userService.deletePortalUserAccount(userId),
    {
      onSuccess: async () => {
        logout();
        queryClient.invalidateQueries();
        history.push('/account-delete-success');
      },
      onError: () => {
        toast.error(
          'Something went wrong. Please contact support to assist you in removing your account.',
        );
      },
    },
  );

  const _handleFormSubmitError = (error: ErrorObject, actions: FormikHelpers<EditAccountProps>) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!_.isEmpty(apiErrors)) {
      actions.setFieldError('firstName', apiErrors.firstName);
      actions.setFieldError('lastName', apiErrors.lastName);
      actions.setFieldError('email', apiErrors.email);
      actions.setFieldError('email', apiErrors.DuplicateEmail);
      actions.setFieldError('password', apiErrors.password);
      actions.setFieldError('termsChecked', apiErrors.termsChecked);
      actions.setFieldError('cellphoneNumber', apiErrors.cellphoneNumber);
      actions.setFieldError('cellphone', apiErrors.cellphone);
      actions.setFieldError('code', apiErrors.code);
    } else {
      actions.setFieldError('termsChecked', error.message);
    }
  };

  const _handleSubmission = (
    formData: EditAccountProps,
    actions: FormikHelpers<EditAccountProps>,
  ) => {
    const intCode = `+${formData.code}`;
    const cellNum = formData.cellphone.replace(/^0+/, '');

    formData.cellphoneNumber = intCode + cellNum;

    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
        onFailure();
      });
  };

  const _onLoginClick = () => {
    history.push('/login');
  };

  const onClickDeleteAccount = async () => {
    // TODO: Replace with built in confirmation dialog
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you wish to delete your profile?')) {
      mutateDeleteuser();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        editAccount
          ? isFleetOnly
            ? editFleetOnlyAccountValidation
            : editAccountValidation
          : signUpValidation
      }
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
      enableReinitialize
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
      }) => {
        return (
          <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
            <p className={`auth-heading ${editAccount ? 'text-center' : null}`}>
              {editAccount ? 'Account Details' : 'Sign up Details'}
            </p>
            <FormTextField
              variableName="firstName"
              placeholder="First Name"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/user-icon.png" alt="userName" />}
              required
              className="w-4/5 self-center"
            />
            <FormTextField
              variableName="lastName"
              placeholder="Last Name"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/user-icon.png" alt="lastName" />}
              required
              className="w-4/5 self-center"
            />
            <>
              <FormTextField
                variableName="email"
                placeholder="Email"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
                required
                className="w-4/5 self-center"
              />
              <FormTextField
                variableName="confirmEmail"
                placeholder="Confirm Email"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
                required
                className="w-4/5 self-center"
              />
              {onboardStatus.data.individual && (
                <OffenderTypeTextField
                  variableType="identifierType"
                  variableTypes={individualProfileTypes}
                  variableName="idNumber"
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  required
                  className="mb-2 w-full"
                  containerClassName="flex w-4/5"
                  placeholder={OffenderIdentifierTypeDescription[values.identifierType]}
                />
              )}
            </>
            <div className="w-4/5 self-center flex justify-between">
              <FormTextField
                variableName="code"
                placeholder="Code"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                icon={<img src="assets/icons/plus-icon.png" alt="code" />}
                required
                className="w-3/12"
              />
              <FormTextField
                variableName="cellphone"
                placeholder="Cellphone"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                icon={<img src="assets/icons/phone-icon.png" alt="phone" />}
                required
                className="w-8/12"
              />
            </div>
            <div className="flex flex-col w-4/5">
              <small className="text-left">Additional Communication Recipient</small>
              <FormTextField
                variableName="communicationEmail"
                placeholder="Communication Email"
                values={values}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
                className="w-full self-center"
              />
            </div>
            <div className="flex place-items-center w-4/5 self-center">
              <Checkbox
                checked={values.OptInWhatsApp}
                name="OptInWhatsApp"
                id="OptInWhatsApp"
                color="secondary"
                onChange={handleChange('OptInWhatsApp')}
                onBlur={handleBlur('OptInWhatsApp')}
              />
              <div className="flex items-center">
                <Typography className="self-center">
                  I want to receive FinesSA communications via WhatsApp{' '}
                  <WhatsApp className="text-total-green" />
                </Typography>
              </div>
            </div>
            <div className="flex place-items-center w-4/5 self-center">
              <Checkbox
                checked={values.OptInEmail}
                name="OptInEmail"
                id="OptInEmail"
                color="secondary"
                onChange={handleChange('OptInEmail')}
                onBlur={handleBlur('OptInEmail')}
              />
              <div className="flex items-center">
                <Typography className="self-center">
                  I want to receive FinesSA communications via Email{' '}
                  <Email className="text-black" />
                </Typography>
              </div>
            </div>
            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit}
              className="auth-form-submit-button"
            >
              {editAccount ? 'Update' : 'Sign Up'}
            </Button>
            {!editAccount && (
              <div className="self-center flex flex-row">
                <Typography className="self-center">{'Do you have an account? '}</Typography>
                <Button type="text" className="font-bold -ml-1" onClick={_onLoginClick}>
                  Log In
                </Button>
              </div>
            )}
            <Button
              isLoading={isLoadingDeleteUser}
              disableRipple
              onClick={onClickDeleteAccount}
              className="mr-auto text-red-600 shadow-none bg-transparent h-3 hover:shadow-none hover:bg-transparent focus:shadow-none focus:bg-transparent py-5 pl-20"
              type="text"
              tabIndex={-1}
            >
              Click here to Delete Account
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
