import React, { useEffect } from 'react';

import { login } from '../../../services/api/auth-network/auth-service';

const LoginScreen: React.FC = () => {
  useEffect(() => {
    login();
  }, []);
  return <></>;
};

export default LoginScreen;
