import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Card, Checkbox, Chip, CircularProgress } from '@material-ui/core';
import numberFormatter from 'number-formatter';
import ReactSelect, { ValueType } from 'react-select';

import { useHistory } from 'react-router';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import {
  TailwindButton,
  PaidFineSummary,
  PaidTableRow,
  UnpaidFineSummary,
  UnpaidTableRow,
} from '../../../components';
import {
  BusinessEntity,
  FineDetailDto,
  GroupedBusinessFine,
  PortalFineStatus,
} from '../../../types';
import { useBusinessEntities } from '../../../react-queries';
import { useResponsive } from '../../../hooks';
import { decodedToken } from '../../../services';
import { syncFinesAction } from '../../../reducers/sync-fines-reducer/sync-fines.actions';
import { syncFineSelector } from '../../../reducers/sync-fines-reducer/sync-fines.reducer';
import finesService from '../../../services/api/fines/fines.api.service';
import {
  businessProfileSelector,
  excludeOffenderIndentifiersFromActiveFines,
  setSelectedBusinessEntityAction,
  setSelectedFinesAction,
  setSelectedOffenderIdentifiersAction,
  toggleSelectedFineAction,
} from '../../../reducers/business-profile-reducer/business-profile-reducer';
import { useDownloadFile, useUserId } from '../../../hooks/global';

const customStyles = () => {
  return {
    overlay: {
      zIndex: 10,
      background:
        'linear-gradient(290deg, #061F40D9 0%, #031020D9 10%, #101925D9 30%, #0C0C0CD9 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 20px 50px #3E3E3E4D',
      filter: 'blur(0px)',
    },
    content: {
      top: '50%',
      left: '50%',
      width: '215px',
      height: '158px',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '30px',
      padding: 0,
      zIndex: 100,
      opacity: 1,
    },
  };
};

Modal.setAppElement('#root');

const BusinessScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const responsive = useResponsive();
  const { userId } = useUserId();

  const { selectedBusinessEntityOption, selectedOffenderIdentifiersOptions, selectedFines } =
    useSelector(businessProfileSelector);
  const { isLoading, syncSuccessful } = useSelector(syncFineSelector);

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [fineStatusFilter, setFineStatusFilter] = useState<PortalFineStatus>('Unpaid');

  const isUnpaidState = useMemo(() => fineStatusFilter === 'Unpaid', [fineStatusFilter]);
  const isPaidState = useMemo(() => fineStatusFilter === 'Paid', [fineStatusFilter]);

  const useBusinessEntitiesQuery = useBusinessEntities();

  const basketTotal = useMemo<number>(
    () =>
      selectedFines.reduce((accumulator, currentFine) => accumulator + currentFine.total, 0) ?? 0,
    [selectedFines],
  );

  useEffect(() => {
    if (userId === '') return;
    dispatch(syncFinesAction());
  }, [userId]);

  useEffect(() => {
    if (syncSuccessful) {
      queryClient.invalidateQueries('finesEntityLinkedData');
      queryClient.invalidateQueries('businessFines');
    }
  }, [syncSuccessful]);

  const isSelectedBusinessEntityRemoved = (
    currentBusinesses: { value: string; label: string }[],
    currentlySelectedBusiness: { value: string; label: string },
  ) => {
    return (
      currentBusinesses.find((business) => business.value === currentlySelectedBusiness.value) ===
      undefined
    );
  };

  const businessEntityOptionsQuery = useQuery<
    BusinessEntity[],
    unknown,
    ValueType<{ label: string; value: string }, false>[]
  >('businessEntities', finesService.fetchBusinessFilterData, {
    select: (businessEntities: BusinessEntity[]) =>
      businessEntities.map((businessEntity) => ({
        value: businessEntity.id,
        label: businessEntity.businessName,
      })),
    onSuccess: (businessEntityOptions) => {
      const canSetSelectedBusinessEntity =
        selectedBusinessEntityOption === null
          ? true
          : isSelectedBusinessEntityRemoved(businessEntityOptions, selectedBusinessEntityOption);
      if (canSetSelectedBusinessEntity) {
        dispatch(setSelectedBusinessEntityAction(_.first(businessEntityOptions)));
      }
    },
  });

  const businessEntityLinkOptionsLookupQuery = useQuery(
    'businessEntities',
    finesService.fetchBusinessFilterData,
    {
      select: (businessEntities) => {
        return businessEntities.reduce((previousValue, currentValue) => {
          previousValue[currentValue.id] = currentValue.offenderIdentifiers.map(
            (offenderIdentifier) => ({
              value: offenderIdentifier.value,
              label: offenderIdentifier.value,
            }),
          );
          return previousValue;
        }, {});
      },
    },
  );

  const selectedOffenderIdentifiers = useMemo(
    () => selectedOffenderIdentifiersOptions.map((o) => o.value),
    [selectedOffenderIdentifiersOptions],
  );

  const finesQuery = useQuery<GroupedBusinessFine[], unknown, GroupedBusinessFine[]>(
    [
      'businessFines',
      fineStatusFilter,
      selectedBusinessEntityOption?.value,
      ...selectedOffenderIdentifiers,
    ],
    () =>
      finesService.getFleetFilteredFine(
        selectedBusinessEntityOption?.value,
        selectedOffenderIdentifiers,
        fineStatusFilter,
      ),
    {
      initialData: () => [],
      enabled: useBusinessEntitiesQuery?.data?.length > 0,
      onSuccess: (businessEntityFines) => {
        if (selectedFines.length === 0) {
          const payableFines = businessEntityFines
            .flatMap((s) => s.fines)
            ?.filter((s) => s.isPayable);
          dispatch(setSelectedFinesAction(payableFines));
        }
      },
    },
  );

  const selectAllFines = () => {
    const payableFines = finesQuery.data.flatMap((s) => s.fines)?.filter((s) => s.isPayable);
    dispatch(setSelectedFinesAction(payableFines || []));
  };

  const openModal = (type?: string) => {
    setModalType(type);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (isLoading) {
      openModal('fineSyncing');
    }
    if (!isLoading) {
      closeModal();
    }
  });

  const _toggleFineSelected = (fine: FineDetailDto) => () => {
    dispatch(toggleSelectedFineAction(fine));
  };

  const _isFineSelected = (fine: FineDetailDto): boolean => {
    return selectedFines?.findIndex((f) => f.id === fine.id) !== -1;
  };

  const areAllFinesSelected = () => {
    const payableFines = finesQuery?.data?.flatMap((s) => s.fines)?.filter((s) => s.isPayable);
    return payableFines?.length === selectedFines?.length && selectedFines?.length !== 0;
  };

  const _toggleAllFinesSelected = () => {
    if (areAllFinesSelected()) {
      dispatch(setSelectedFinesAction([]));
    } else {
      selectAllFines();
    }
  };

  const _getPaidFines = () => {
    setFineStatusFilter('Paid');
  };

  const _getUnpaidFines = () => {
    setFineStatusFilter('Unpaid');
  };

  const { download: _downloadFinesContentPdf } = useDownloadFile({
    apiDefinition: () =>
      finesService.downloadFleetFilteredFinesContent(
        selectedBusinessEntityOption?.value,
        selectedBusinessEntityOption?.label,
        selectedOffenderIdentifiers,
        fineStatusFilter,
      ),
    onError: () => toast.error('Could not download fleet fines'),
  });

  const _paySelectedFines = () => {
    history.push('/business-basket', selectedFines);
  };

  const isButtonDisabled = useMemo(() => selectedFines.length === 0, [selectedFines]);

  const _renderUnpaidFines = () => {
    return (
      <div className="flex flex-col w-full h-full">
        {responsive.isMedium && (
          <Card className="flex flex-row px-2 place-items-center">
            <Checkbox
              onChange={_toggleAllFinesSelected}
              checked={areAllFinesSelected()}
              className="flex flex-grow-0 self-start"
            />
            <p>Select All</p>
          </Card>
        )}
        {finesQuery?.data?.length === 0 ? (
          <div className="flex flex-grow justify-center items-center my-2 xs:my-0">
            <span className="rounded-3xl p-1 text-black font-medium bg-form-background text-center">
              Congratulations !!!! <br />
              <br />
              You currently have no outstanding traffic fines. <br />
              Please SIGN IN regularly as the portal is updated in real time. <br />
              We will also email you a monthly statement if you get any new fines. <br />
              <br />
              Safe Motoring, Fines SA team
            </span>
          </div>
        ) : (
          _.map(finesQuery.data, (entity) => {
            const fines = _.map(entity.fines, (localFine) => {
              return (
                <UnpaidFineSummary
                  key={localFine.id}
                  onCheck={_toggleFineSelected(localFine)}
                  checked={_isFineSelected(localFine)}
                  fine={localFine}
                  hasImage={localFine.evidence.length > 0}
                />
              );
            });
            return (
              <div className="flex flex-col" key={`offender-${entity.offenderIdentifier}`}>
                <h2 className="text-white font-medium my-5">{`${entity.offenderIdentifier}`}</h2>
                {fines}
              </div>
            );
          })
        )}
      </div>
    );
  };
  const _renderPaidFines = () => {
    return (
      <>
        {finesQuery.data.length !== 0 ? (
          <div className="w-full h-full">
            {_.map(finesQuery.data, (entity) => {
              return (
                <>
                  <h2 className="text-white font-medium my-5">{`${entity.offenderIdentifier}`}</h2>
                  {_.map(entity.fines, (fine) => {
                    return <PaidFineSummary key={fine.id} fine={fine} />;
                  })}
                </>
              );
            })}
          </div>
        ) : (
          <h2 className="text-white font-medium my-5">No fines paid.</h2>
        )}
      </>
    );
  };

  const _renderUnpaidHeaders = () => {
    if (!responsive.isMobile) {
      return (
        <div className="w-full h-14">
          <UnpaidTableRow
            className="h-11 md:flex hidden"
            checked={areAllFinesSelected()}
            onCheck={_toggleAllFinesSelected}
            columns={[
              <p className="font-semibold">Fine Information</p>,
              <p className="font-semibold">Fine Amount</p>,
              <p className="font-semibold">Discount</p>,
              <p className="font-semibold">Other Fees</p>,
              <p className="font-semibold">Demerit Points</p>,
              <p className="font-semibold">Amount Paid</p>,
              <p className="font-semibold">Amount Outstanding</p>,
              <p className="font-semibold">Icon</p>,
            ]}
          />
        </div>
      );
    }
    return null;
  };

  const _renderPaidHeaders = () => {
    if (!responsive.isMobile) {
      return (
        <div className="w-full h-14">
          <PaidTableRow
            className="h-11 xs:flex hidden"
            columns={[
              <p className="font-semibold ">Fine Information</p>,
              <p className="font-semibold ">Fine Amount</p>,
              <p className="font-semibold">Total Paid</p>,
              <p className="font-semibold">PaymentType</p>,
            ]}
          />
        </div>
      );
    }
    return null;
  };

  if (
    businessEntityOptionsQuery.isLoading ||
    businessEntityLinkOptionsLookupQuery.isLoading ||
    finesQuery.isLoading
  ) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="w-full h-full px-5 flex flex-col">
        <div className="flex md:flex-row flex-col w-full mb-5">
          <TailwindButton
            onClick={_getUnpaidFines}
            variant="contained"
            className={`bg-white hover:bg-gray-100 ${
              isUnpaidState ? 'text-secondary z-10' : 'text-text-grey'
            }`}
          >
            Unpaid Fines
          </TailwindButton>
          <TailwindButton
            onClick={_getPaidFines}
            className={`bg-white hover:bg-gray-100 md:-ml-2  md:mt-0 -mt-1 mb-2 md:mb-0 ${
              isPaidState ? 'text-secondary z-10' : 'text-text-grey'
            }`}
          >
            Paid Fines
          </TailwindButton>
          <ReactSelect
            options={businessEntityOptionsQuery.data}
            value={selectedBusinessEntityOption}
            isLoading={useBusinessEntitiesQuery.isLoading}
            defaultValue={_.first(businessEntityOptionsQuery.data)}
            className="bg-white rounded-md md:mx-4 h-9 w-60 no-underline mb-2 md:mb-0 flex-initial"
            onChange={(newSelected) => {
              if (newSelected.value !== selectedBusinessEntityOption.value) {
                dispatch(setSelectedBusinessEntityAction(newSelected));
                dispatch(setSelectedOffenderIdentifiersAction([]));
                dispatch(setSelectedFinesAction([]));
              }
            }}
          />
          <ReactSelect
            placeholder="Filter BRN/ProxyId"
            isMulti
            className="bg-white rounded-md md:mx-4 h-9 no-underline mb-2 md:mb-0 flex-auto"
            options={
              businessEntityLinkOptionsLookupQuery?.data[selectedBusinessEntityOption?.value]
            }
            value={selectedOffenderIdentifiersOptions}
            onChange={(values: ValueType<{ label: string; value: string }, false>[]) => {
              dispatch(setSelectedOffenderIdentifiersAction(values));
              dispatch(excludeOffenderIndentifiersFromActiveFines(values.map((s) => s.value)));
            }}
          />
          <div className="flex flex-1" />
          {isUnpaidState && !responsive.isMobile && (
            <div className="flex flex-row">
              <div className="flex flex-1" />
              <Chip
                label={`TOTAL: R ${
                  basketTotal !== 0 ? numberFormatter('#,###.', basketTotal) : '0'
                }`}
                className="text-white xs:mr-6 mb-2 xs:mb-0 h-9 bg-total-green font-semibold text-sm px-3 rounded-xl"
                variant="outlined"
              />
              <TailwindButton
                onClick={_downloadFinesContentPdf}
                isDisabled={isButtonDisabled}
                variant="contained"
                className="bg-gray-400 xs:mr-2 hover:bg-gray-500 focus:bg-gray-500"
              >
                <img src="/assets/icons/download-white-icon.png" alt="logout" className="h-4" />
                &nbsp;PDF
              </TailwindButton>
              <TailwindButton
                onClick={_paySelectedFines}
                isDisabled={isButtonDisabled}
                variant="contained"
                className="bg-tertiary hover:bg-tertiary-dark focus:bg-tertiary-dark"
              >
                Pay Selected Fines
              </TailwindButton>
            </div>
          )}
        </div>
        {isUnpaidState ? _renderUnpaidHeaders() : _renderPaidHeaders()}
        <div className="w-full overflow-y-auto h-full inline-block my-1 rounded">
          <div className="h-full w-full">
            {isUnpaidState ? _renderUnpaidFines() : _renderPaidFines()}
            {isUnpaidState && responsive.isMobile && (
              <div className="flex flex-col">
                <div className="flex flex-1" />
                <Chip
                  label={`TOTAL: R ${
                    basketTotal !== 0 ? numberFormatter('#,###.', basketTotal) : '0'
                  }`}
                  className="text-white xs:mx-6  mb-2 xs:mb-0 h-9 bg-total-green font-semibold text-xl px-3 rounded-xl"
                  variant="outlined"
                />
                <TailwindButton
                  onClick={_paySelectedFines}
                  className="bg-tertiary hover:bg-tertiary-dark focus:bg-tertiary-dark text-white font-medium rounded-xl px-3 h-9"
                >
                  <img src="assets/icons/mail-icon.png" alt="mail" className="mr-2" />
                  Pay Selected Fines
                </TailwindButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles()}
        contentLabel="Mobile Modal"
      >
        {modalType === 'fineSyncing' && (
          <div className="flex w-full h-full">
            <div className="flex flex-grow justify-center items-center">
              <CircularProgress />
              <div className="ml-3">Fines Loading...</div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default BusinessScreen;
