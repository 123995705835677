import { useEffect, useState } from 'react';
import { User } from 'oidc-client';

import { getUser, userManager } from '../../services/api/auth-network/auth-service';

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserSessionStatusAsync = async () => {
    try {
      const status = await getUser();
      setUser(status);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserSessionStatusAsync();

    const handleUserLoaded = () => {
      fetchUserSessionStatusAsync();
    };

    userManager.events.addUserLoaded(handleUserLoaded);

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
    };
  }, []);

  return { user, loading, error };
};
