import { WebStorageStateStore } from 'oidc-client';

import appConfig from '../../../config';

const { hostUrl, redirectUrl } = appConfig;
export const oidcConfig = {
  authority: hostUrl,
  client_id: 'web',
  redirect_uri: `${redirectUrl}/callback`,
  response_type: 'code', // Use 'code' for Authorization Code Flow
  scope: 'openid profile email finesApi offline_access',
  post_logout_redirect_uri: redirectUrl,
  loadUserInfo: true, // Optional: to load user info after login
  response_mode: 'query', // Optional: for query response mode
  automaticSilentRenew: false, // Optional: to renew tokens automatically
  // silent_redirect_uri: 'http://localhost:3000/silent-renew.html', // Optional: for silent renew
  useCodeForRefreshToken: true, // Use PKCE
  end_session_endpoint: `${hostUrl}/connect/endsession`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};
