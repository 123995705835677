import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useOnboardStatus } from '../../../react-queries';
import { RoleEnum } from '../../../enums/role.enum';
import { decodedToken } from '../../../services';

const LandingScreen: React.FC = () => {
  const onboardStatus = useOnboardStatus();
  const history = useHistory();

  useEffect(() => {
    const routeUser = async () => {
      const role = await decodedToken.getRoleAsync();
      if (role === RoleEnum[2]) {
        if (!onboardStatus.data) {
          onboardStatus.refetch().then((onboardStatusresponse) => {
            if (onboardStatusresponse.data.individual) {
              history.replace(`/individual${history.location.search}`);
            } else if (onboardStatusresponse.data.fleet) {
              history.replace(`/business${history.location.search}`);
            } else {
              history.replace('/onboard');
            }
          });
        }
      } else {
        history.replace('/admin');
      }
    };

    routeUser();
  }, []);

  return <></>;
};

export default LandingScreen;
