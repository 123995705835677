import { AxiosResponse } from 'axios';

import {
  Guid,
  InvoiceDetailDto,
  InvoiceFilterOptions,
  Invoices,
  PaginatedList,
  ServiceResult,
} from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import { CorrespondenceType } from '../user-auth/types';
import { ContentTypeEnum } from '../../../enums/content-type.enum';
import adminInvoicesUrls from './admin-invoices.urls';

export const getInvoices = (filter: InvoiceFilterOptions): Promise<PaginatedList<Invoices>> => {
  const url = adminInvoicesUrls.getInvoicesUrl(filter);

  return authNetworkService.get(url).then((apiResponse: AxiosResponse<PaginatedList<Invoices>>) => {
    return apiResponse.data;
  });
};

const sendInvoiceCorrespondence = (invoiceId: string, type: CorrespondenceType) => {
  const url = adminInvoicesUrls.invoiceCorrespondenceUrl();
  return authNetworkService.post(url, { invoiceId, type });
};

export const getInvoiceById = (invoiceId: string): Promise<AxiosResponse<Blob>> => {
  const url = adminInvoicesUrls.getInvoiceUrl(invoiceId);

  return authNetworkService.get(url, { responseType: 'blob' });
};

export const getInvoicePdf = (
  invoiceId: string,
  contentType = ContentTypeEnum.pdf,
): Promise<AxiosResponse<Blob>> => {
  const url = adminInvoicesUrls.getInvoiceContentUrl(invoiceId, contentType);

  return authNetworkService.get(url, { responseType: 'blob' });
};

const createInvoice = (
  userId: string,
  fleetEntityId?: string,
  fineIds?: string[],
): Promise<string> => {
  const url = adminInvoicesUrls.createInvoiceUrl();

  return authNetworkService
    .post(url, { userId, fleetEntityId, fineIds })
    .then((apiResponse: AxiosResponse<ServiceResult<string>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getIndividualInvoice = (invoiceId: string) => {
  const url = adminInvoicesUrls.getIndividualInvoiceUrl(invoiceId);
  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<InvoiceDetailDto>>) => apiResponse.data.data);
};

const setPoNumber = (id: Guid, poNumber: string) => {
  const url = adminInvoicesUrls.getInvoiceUrl(id);
  const patchDto = {
    poNumber,
  };
  return authNetworkService.patch(url, patchDto);
};

const updateAdditionalInformationKey = (id: Guid, additionalInformationKey: string) => {
  const url = adminInvoicesUrls.getInvoiceUrl(id);
  const patchDto = {
    additionalInformationKey,
  };
  return authNetworkService.patch(url, patchDto);
};

const updateAdditionalInformationValue = (id: Guid, additionalInformationValue: string) => {
  const url = adminInvoicesUrls.getInvoiceUrl(id);
  const patchDto = {
    additionalInformationValue,
  };
  return authNetworkService.patch(url, patchDto);
};

export default {
  getInvoices,
  createInvoice,
  sendInvoiceCorrespondence,
  getInvoicePdf,
  getInvoiceById,
  getIndividualInvoice,
  setPoNumber,
  updateAdditionalInformationKey,
  updateAdditionalInformationValue,
};
