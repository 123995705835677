import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { RouteLayoutProps } from '../../../types';
import { useUserRole } from '../../../hooks/global';
import { useUser } from '../../../hooks/global/use-user-session-status';
import { logout } from '../../../services';

export const ProtectedRoute: React.FC<RouteLayoutProps> = ({
  component: Component,
  layout: Layout,
  roles,
  ...rest
}) => {
  const { role, loading: loadingUserRole } = useUserRole();
  const { user, loading: loadingSessionStatus } = useUser();

  return loadingUserRole || loadingSessionStatus ? (
    <>Loading...</>
  ) : (
    <Route
      {...rest}
      render={(props) => {
        const isAuthenticated = user && !user.expired;
        if (user && user.expired) {
          logout();
        }
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
        const userRole = role;
        if (roles && roles.indexOf(userRole) === -1) {
          return <Redirect to={{ pathname: '/' }} />;
        }
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};
