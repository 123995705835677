import React from 'react';
import { useQuery } from 'react-query';
import { EditFineForm } from '../../forms/edit-fine-form/edit-fine-form';
import { EditFineProps, Guid } from '../../../types';
import adminFinesService from '../../../services/api/admin-fines/admin-fines.service';
import { useUserRole } from '../../../hooks/global';

export type EditFineFormFetcherProps = {
  fineId: Guid;
  submitForm: (formData: EditFineProps) => any;
  onSuccess: () => unknown;
  onFailure: () => unknown;
  isArchived: boolean;
};

export const EditFineFormWithFetch: React.FC<EditFineFormFetcherProps> = ({
  submitForm,
  onSuccess,
  onFailure,
  fineId,
  isArchived,
}) => {
  const fineQuery = useQuery(['editFine', fineId], () => adminFinesService.getFineById(fineId));
  const { role: userRole } = useUserRole();

  if (fineQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <EditFineForm
      initialValues={{
        vehicleRegistration: fineQuery.data.vehicleRegistration,
        offenceDate: new Date(fineQuery.data.offenceDate),
        municipality: fineQuery.data.municipality,
        offenceLocation: fineQuery.data.offenceLocation,
        offenceDescription: fineQuery.data.offenceDescription,
        manualDiscount: fineQuery.data.manualDiscount,
        noticeType: fineQuery.data.noticeType,
        amountDue: fineQuery.data.amountDue,
        serviceFee: fineQuery.data.serviceFee,
        isArchived: !!isArchived,
      }}
      submitForm={submitForm}
      onSuccess={onSuccess}
      onFailure={onFailure}
      meta={{
        id: fineQuery.data.id,
        isNag: fineQuery.data.isNag,
        noticeType: fineQuery.data.noticeType,
        noticeTypeKnown: fineQuery.data.noticeTypeKnown,
        offenderIdentifier: fineQuery.data.offenderIdentifier,
        isAarto: fineQuery.data.isAarto,
        status: fineQuery.data.status,
        role: userRole,
        isArchived: !!isArchived,
      }}
    />
  );
};
