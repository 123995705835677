import appConfig from '../../../config';

const { hostUrl, apiUrl } = appConfig;
const apiV1Url: string = apiUrl();
const apiV2Url: string = apiUrl(2);
export default {
  tokenUrl: (): string => `${hostUrl}/connect/token`,
  signInWithExternal: (): string => `${apiV1Url}/Accounts/external-sign-in`,
  signUpUrl: (): string => `${apiV1Url}/Accounts/sign-up`,
  verifyAccountUrl: (): string => `${apiV1Url}/Accounts/verify-email`,
  verifyNewEmailUrl: (): string => `${apiV1Url}/Accounts/verify-new-email`,
  forgotPasswordUrl: (): string => `${apiV1Url}/Accounts/forgot-password`,
  resetPasswordUrl: (): string => `${apiV1Url}/Accounts/reset-password`,
  unlockAccountUrl: (): string => `${apiUrl()}/users/unlock_account`,
  initialPasswordUrl: (): string => `${apiV1Url}/Accounts/validate-initial-sign-up`,
  canSignUpUrl: (): string => `${apiV1Url}/Accounts/can-sign-up`,
  signUpV2: (): string => `${apiV2Url}/Accounts/sign-up`,
};
