import jwtDecode from 'jwt-decode';

import { JwtPayload } from '../../types/role/role.types';
import { getUser } from '../api/auth-network/auth-service';

const getRoleAsync = async () => {
  const user = await getUser();
  const token = user ? jwtDecode<JwtPayload>(user.access_token.toString()) : { role: '' };
  return token.role;
};
const getUserIdAsync = async () => {
  const user = await getUser();
  const token = user ? jwtDecode<JwtPayload>(user.access_token.toString()) : { sub: '' };
  return token.sub;
};

export default { getRoleAsync, getUserIdAsync };
