import React from 'react';

const DeleteInstructionsScreen: React.FC = () => {

  return (
    <div className='w-2/5 flex flex-col pb-10'>
      <p className='text-lg font-sans font-semibold'>How to delete your account</p>
      <div className='flex flex-col'>
        <p className='text-sm pb-7'>Step 1:</p>
        <div className='flex justify-between'>
          <p className='text-sm'>Press on the Add/Edit button. To open your profile page.</p>
          <img
            src="/assets/images/step-1.png"
            alt="Step 1"
            className="step-1"
            width="500"
          />
        </div>
      </div>
      <div className='flex flex-col'>
        <p className='text-sm pb-7'>Step 2:</p>
        <div className='flex justify-between'>
          <p className='text-sm'>Press the “Click here to Delete Account” button.</p>
          <img
            src="/assets/images/step-2.png"
            alt="Step 2"
            className="step-2"
            width="400"
          />
        </div>
      </div>
      <div className='flex flex-col'>
        <p className='text-sm pb-7'>Step 3:</p>
        <div className='flex justify-between'>
          <p className='text-sm'>Confirm the deletion of your account by clicking the “OK” button.</p>
          <img
            src="/assets/images/step-3.png"
            alt="Step 3"
            className="step-3"
            width="400"
          />
        </div>
      </div>
      <div className='flex flex-col'>
        <p className='text-sm pb-7'>Step 4:</p>
        <div className='flex justify-between'>
          <p className='text-sm'>Once your account is successfully deleted, you will receive a confirmation message with a link to return to the login page.</p>
          <img
            src="/assets/images/step-4.png"
            alt="Step 4"
            className="step-4"
            width="400"
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteInstructionsScreen;
