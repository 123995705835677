import { useEffect, useState } from 'react';

import { decodedToken } from '../../services';

export const useUserRole = () => {
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoleAsync = async () => {
      try {
        const tokenRole = await decodedToken.getRoleAsync();
        setRole(tokenRole);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRoleAsync();
  }, []);

  return { role, loading, error };
};
