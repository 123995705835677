import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { getUser } from '../api/auth-network/auth-service';

const createAttachTokenInterceptor = (axiosInstance: AxiosInstance) => {
  const _attachAccessToken = async (_config: AxiosRequestConfig) => {
    const user = await getUser();
    const accessToken = user?.access_token;
    const config = _config;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  };

  return axiosInstance.interceptors.request.use(_attachAccessToken);
};
export default createAttachTokenInterceptor;
