import { useEffect, useState } from 'react';

import { decodedToken } from '../../services';

export const useUserId = () => {
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initialiseUserIdAsync = async () => {
      try {
        const tokenRole = await decodedToken.getUserIdAsync();
        setUserId(tokenRole);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    initialiseUserIdAsync();
  }, []);

  return { userId, loading, error };
};
