import { UserManager } from 'oidc-client';
import { oidcConfig } from './oidc-config';

export const userManager = new UserManager(oidcConfig);

export const login = async () => {
  await userManager.signinRedirect();
};

export const logout = async () => {
  await userManager.signoutRedirect();
};

export const getUser = () => {
  return userManager.getUser();
};

export const renewToken = async () => {
  try {
    await userManager.signinSilent();
  } catch (exception) {
    // await userManager.removeUser();
    await logout();
  }
};

export const removeUser = () => {
  return userManager.removeUser();
};

export const signinRedirectCallback = () => {
  return userManager.signinRedirectCallback();
};

export const querySessionStatus = () => {
  return userManager.querySessionStatus();
};

export const signinCallback = () => {
  return userManager.signinCallback();
};

export const userManagerTest = () => {
  return userManager;
};

userManager.events.addUserLoaded((user) => {
  // console.log('New User Loaded：', user);
  // console.log('Access_token: ', user.access_token);
});

userManager.events.addAccessTokenExpired(() => {
  console.log('Token expired');
  renewToken();
});
