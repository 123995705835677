import React from 'react';
import { CallCentreManangementOrganism } from '../../../components/organisms/management-call-centre-organism/management-call-centre-organism';
import { CallCentreOrganism } from '../../../components/organisms/call-centre-organism/call-centre-organism';
import { useUserRole } from '../../../hooks/global';

const CallCentreScreen: React.FC = () => {
  const { role: userRole } = useUserRole();

  return userRole === 'SuperAdministrator' || userRole === 'CallCenterManager' ? (
    <CallCentreManangementOrganism />
  ) : userRole === 'CallCenterAgent' ? (
    <CallCentreOrganism />
  ) : null;
};

export default CallCentreScreen;
