import React from 'react';
import { useHistory } from 'react-router-dom';

import { signinCallback } from '../services/api/auth-network/auth-service';

const Callback = () => {
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      try {
        await signinCallback();
      } finally {
        history.push('/');
      }
    })();
  });

  return <h1>Signing in...</h1>;
};

export default Callback;
