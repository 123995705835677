import ax from 'axios';

import {
  createAttachTokenInterceptor,
  createNetworkErrorHandlerInterceptor,
} from '../../interceptors';

const authNetworkService = ax.create({
  timeout: 25000,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  responseType: 'json',
});

createAttachTokenInterceptor(authNetworkService);
createNetworkErrorHandlerInterceptor(authNetworkService);

export default authNetworkService;
